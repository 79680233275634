import React from 'react';
import styles from './Navbar.module.css'
//import vitaco from '../images/VITACCO.png'
//import Sidebar from './Sidebar';
import { Navbar, Link, Text} from "@nextui-org/react";

function Navigationbar() {
  return (
    <Navbar isBordered mobile variant="sticky" className={styles.navigation}>
        <Navbar.Toggle aria-label="toggle navigation" showIn='xs' />
        <Navbar.Brand>

          {/*<Image src={vitaco} hideIn="xs" className={styles.logo}/>*/}
          <Text> Vitacco </Text>
        </Navbar.Brand>
        <Navbar.Content hideIn="xs">
          <Navbar.Link href="/">Home</Navbar.Link>
          <Navbar.Link href="/about-us">About us</Navbar.Link>
          <Navbar.Link href="/products-services">Products & Services</Navbar.Link>
          <Navbar.Link href="/gallery">Gallery</Navbar.Link>
          {/*<Navbar.Link href="/blog">Blog</Navbar.Link>*/}
          <Navbar.Link href="/contact-us">Contact us</Navbar.Link>
        </Navbar.Content>

        <Navbar.Content>
          {/*<img src={globe} alt='globe' width={40} height={40}/> */}
        </Navbar.Content>
        
        <Navbar.Collapse>
            <Navbar.CollapseItem key={0}>
                <Link
                color="inherit"
                css={{
                    minWidth: "100%",
                }}
                href="/"
                >
                Home
                </Link>
            </Navbar.CollapseItem>
            <Navbar.CollapseItem key={1}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/about-us"
            >
              About us
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key={2}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/products-services"
            >
              Products & Services
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key={3}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/gallery"
            >
              Gallery
            </Link>
          </Navbar.CollapseItem>
          {/*<Navbar.CollapseItem key={4}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/blog"
            >
              Blog
            </Link>
            </Navbar.CollapseItem>*/}
          <Navbar.CollapseItem key={4}>
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href="/contact-us"
            >
              Contact us
            </Link>
          </Navbar.CollapseItem>
        </Navbar.Collapse>
      </Navbar>
  )
}

export default Navigationbar