import React from 'react'
import './TermsOfService.css'

const TermsOfService = () => {
    return (
        <div className='terms'>
            <h3>Terms of Service</h3>
            <article>
                    <span>Last updated: February 7, 2023</span>

                    <h3>Introduction</h3>
                    <article>Welcome to Vitacco, a Savings and Credit Cooperative Organization. These Terms of Service ("Terms") govern your use of our website, products, and services. By accessing or using our website or any of our products or services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our website or any of our products or services.</article>

                    <h3>Membership eligibility</h3>
                    <article>To be eligible for membership in Vitacco, you must be at least 18 years old and meet our membership criteria. Our membership criteria may include factors such as your profession, location, or other relevant factors. We reserve the right to deny membership to anyone who does not meet our eligibility criteria.</article>

                    <h3>Website use</h3>
                    <article>
                    You may use our website and the Services offered through it only for lawful purposes and in accordance with these Terms of Service. You agree not to use this website:
                    <ul>
                    <li>In any way that violates any local, or international law or regulation.</li>
                    <li>To impersonate or attempt to impersonate another person or entity.</li>
                    <li>To engage in any activity that is harmful to our website or Services or to any user of our website or Services.</li>
                    <li>To transmit any viruses, malware, or other harmful code or material.</li>
                    <li>To use our website or Services for any commercial or business purposes, unless we have given you prior written consent.</li>
                    </ul>
                    </article>

                    <h3>Intellectual Property</h3>
                    <article>
                    The content and materials made available through our website and products and services, including text, graphics, images, and software, are owned by Vitacco or our licensors and are protected by copyright and other intellectual property laws. You may not copy, distribute, modify, or create derivative works based on any such content or materials without our prior written consent.
                    </article>
                    <h3>Responsibility and Liability</h3>
                    <article>
                    We do not own or control any of the travel providers whose products and services are offered through our website and Services.
                    We are not responsible for the acts or omissions of any travel provider, including but not limited to, their failure to provide the services you have booked.
                    We are not responsible for any loss, damage, or injury that you may incur as a result of your use of our Services, including but not limited to, any loss, damage, or injury that results from the acts or omissions of any travel provider.
                    Your use of our Services is at your own risk.
                    </article>
                    <h3>Governing Law and Dispute Resolution</h3>
                    <article>These Terms and your use of our website, products, and services are governed by and construed in accordance with the laws of the jurisdiction where Vitacco is located, without giving effect to any choice of law or conflict of law provisions.</article>

                    <h3>Disclaimer of Warranty</h3>
                    <article>Our Services are provided on an “as is” and “as available” basis, without any warranties of any kind, either express or implied. We do not guarantee that our Services will be uninterrupted or error-free.</article>
            </article>
        </div>
    )
}


export default TermsOfService;