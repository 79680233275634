import React from 'react';
import styles from './SeniorSaver.module.css'

function SeniorSaver() {
  return (
    <div className={styles.seniorsaver}>
        <article>Senior Saver is a tailor made loan product for our senior saver members who have garnered Back Office savings of up to KShs. 1.5M.</article>
        <article>Under this particular product, the 1/3 rule does not apply. Members are encouraged to open a Biashara account. You can channel your business proceeds, Salary, Pension or any other cash flows through this account.</article>
        <div>
            <h4>Eligible Members</h4>
            <ul>
                <li>Senior Savers with savings more than their loans and have not guaranteed others can use the difference to get other loans provided the total loan does not exceed 85% of their free deposits.</li>
                <li>The loan must be self-guaranteed.</li>
            </ul>
        </div>

        <div>
            <h4>Product Features</h4>
            <ul>
                <li>Minimum deposit of ksh 1.5 million</li>
                <li>Processing fee of 2%</li>
                <li>Security: member deposits and conventional collaterals (where amount borrowed exceeds member’s deposits)</li>
                <li>Maximum loan amount is Kshs.15 Million</li>
                <li>Maximum loan repayment term is 120 Months</li>
                <li>Loan eligibility is subject to member demonstrating ability to repay, sufficiency of collateral and business appraisal.</li>
            </ul>
        </div>
    </div>
  )
}

export default SeniorSaver
