import React from 'react'
import styles from './BusinessLoans.module.css'

function BusinessLoans() {

    const tableStyle = {
        borderCollapse: 'collapse',
        margin: '12px'
      };
    
      const thStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
      };
    
      const tdStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
      };
  return (
    <div>
        <div className={styles.asset}>
            <h4>Asset Financing</h4>
            <h5><strong>Requirements</strong></h5>
            <article>
                <ul>
                    <li>Fill loan application form.</li>
                    <li>Copy of Identification document.</li>
                    <li>Copy of KRA Pin Certification</li>
                </ul>
            </article>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thStyle} className={styles.terms}>Terms and Conditions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={tdStyle}>
                            <div>
                                <article>The asset being financed shall be jointly registered between Vitacco and the Borrowing Member before loan disbursement.</article>
                                <article>Where the Member is borrowing asset finance to invest in commercial business, the member must demonstrate experience of having been in that line of business and provide proof of;</article>
                                <ul>
                                    <li>Ownership of a fleet of not less than 2 assets which one of them must be debt free.</li>
                                    <li>Repayment track record.</li>
                                </ul>
                                <article>All movable assets financed by Vitacco shall always be installed with GPRS tracking device by a tracking company in Vitacco Panel.</article>
                                <article>Vehicle valuation for used or imported vehicles will be undertaken at Members cost.</article>
                                <article>Costs associated with Insurance, Valuation and Installation of car tracking devices will be borne by the borrowing member.</article>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <h5><strong>Features</strong></h5>
                <ul>
                    <li>Loan repayment period of up to 5 years for brand new vehicles</li>
                    <li>Loan repayment period of up to 3 years for used commercial / personal vehicles.</li>
                    <li>Loan repayment period of up to 2 years for used PSV vehicles.</li>
                    <li>Receive up to 80% financing for brand new vehicles.</li>
                    <li>Up to 70% financing for used commercial / personal vehicles.</li>
                    <li>Up to 60 % financing for used PSV vehicles</li>
                    <li>Minimum monthly income for eligibility - KES 40,000</li>
                    <li>Repayment period from 1 to 5 years</li>
                    <li>Disbursement in cheque/RTGS in favor of the dealer/Vehicle vendor</li>
                </ul>
            </div>
            
        </div>
        <hr></hr>
        <div className={styles.individual}>
            <h4>Individual loans</h4>
            <article>Clients with well established businesses, can access business loans directly without group requirements. The minimum loan for individual client is Kshs 150,000 and a maximum of Kshs 5,000,000.</article>

            <h5>Terms</h5>
            <ol>
                <li>Individual loans are offered at an interest rate of 1.25% p.m. on reducing balance.</li>
                <li>Our repayment period is between 36-60 months depending on the amount borrowed.</li>
                <li>The loan is self-secured and acceptable securities include logbooks and commercial title deeds.</li>
            </ol>
        </div>
        <hr></hr>
        <div className={styles.group}>
            <h4>Group loans</h4>

            <article>Business Loans are disbursed to individual members within a group for expansion of existing income generating ventures.

            <br/>Group loans are done based on tiers.</article>

            <ul>
                <li>Tier one KSH 30,000 – KSH 150,000 (1.33% pm) payable in maximum 12months.</li>
                <li>Tier two KSH 151,000 – KSH 250,000 (1.33% pm) payable in maximum 24months.</li>
                <li>Tier three KSH 251,000 – KSH 500,000 (1.33% pm) payable in maximum 36months.</li>
            </ul>

            <article>One has to start from tier one and graduate through the tiers within the program based on business ability. If successful, at tier three one can move to borrow as an individual client without the need of group.</article>
            <h5>Eligibility</h5>
            <ol>
                <li>Such businesses must have been in operation for at least 6 months and maintained a banking relationship with Vitacco during the period.</li>
                <li>Group must maintain a minimum saving of 25% of the loan amount applied for.</li>
                <li>Minimum monthly savings contribution of KShs. 1,000.00 has to be mainatined throughout the loan term.</li>
                <li>Graduation from one tier to the next shall be pegged on loan repayment track record</li>
                <li>Loans above KShs. 150,000.00 to be secured with conventional collateral.</li>
            </ol>
            <article>Members are requested to confirm and update their nominees with the Sacco to avoid legal tussles. In case of more than one nominee, the percentage allocation for each nominee should be clearly indicated.</article>
        </div>


    </div>
  )
}

export default BusinessLoans