import React, { useState, useEffect } from 'react';
import {Card} from "@nextui-org/react";
import { Swiper, SwiperSlide } from "swiper/react";
//import Sidebar from '../components/Sidebar';
import SwiperCore, {Autoplay} from 'swiper/core';
import axios from 'axios';
import "swiper/css";
import LatestNews from '../components/LatestNews';
import { GiBranchArrow } from 'react-icons/gi'
import styles from './Home.module.css';
import landing from '../images/financelanding1.png';
import financegif from '../images/financial-struggle3.gif';
import bloom35 from '../images/bloom-35.png';
import lagom5 from '../images/lagom-5.png';
import lagomfi from '../images/lagom-financial-growth.png';
import dude from '../images/boba.png'
//import Gallery from '../images/calc.png'
//import Career from '../images/careers.png'
//import faqs from '../images/faqs.png'
//import law from '../images/law.png'
//import bank from '../images/bank.png'
//import test from '../images/birdwatching.jpg'


SwiperCore.use([Autoplay])

function Home() {
  const [imageUrl, setImageUrl] = useState('');

  //const placeholder1 = 'https://source.unsplash.com/random/402x401';
  //const placeholder2 = 'https://source.unsplash.com/random/401x402';
  //const placeholder3 = 'https://source.unsplash.com/random/403x402';
  //const placeholder4 = 'https://source.unsplash.com/random/402x403';

  useEffect(() => {
    const fetchImage = async () => {
      const response = await axios.get('https://api.unsplash.com/photos/random', {
        headers: {
          Authorization: 'Client-ID lV1_j27dcbI0h4h04Lgq-d-WhJ7dqDLzC82cFWiyruo',
        },
        params: {
          query: 'finance',
          orientation: 'landscape',
        },
      });
      setImageUrl(response.data.urls.regular);
    };
    fetchImage();
  }, []);

  return (
    <div className={styles.home}>

      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        autoplay={{delay:7000}}
        loop={true}
        className={styles.myswiper}
      >
        <SwiperSlide><img src={imageUrl} alt='Placeholder' className={styles.image}/></SwiperSlide>
{/*        <SwiperSlide><img src={mountain} alt='test' className={styles.image}/></SwiperSlide>
<SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>*/}
      </Swiper>
        <LatestNews/>
        <div className={styles.quicklinksSacco_container}>
        <div className={styles.landingphoto}>
          <img src={landing} alt="landing page" />
        </div>
            <div className={styles.saccoLoans}>
            <h4>Sacco vs Bank Loans</h4>
            <article>
            SACCO loans are generally free of hidden charges, while bank loans are often associated with a range of additional fees, beyond the regular monthly payments.
            These may include charges such as:
            <ul>
                <li>Insurance fees</li>
                <li>Appraisal fees</li>
                <li>Processing fees</li>
                <li>Accrued interest charges for arrears accounts</li>
                <li>late remittance charges</li>
                <li>Premature loan clearance charges</li>
            </ul>
            </article>
            <a href='/'><button>Read more</button></a>
            </div>
        {/*<div className={styles.quicklinks}>
            <h4>Quick links</h4>
            <div className={styles.quicklinks_row1}>
                <div className={styles.by_laws}>
                    <img src={law} alt = 'law' />
                    <a href='/By-laws'>Our By laws</a>
                </div>
                <div className={styles.gallery}>
                    <img src={Gallery} alt='Gallery'/>
                    <a href='/Gallery'>Photo Gallery</a>

                </div>
                <div className={styles.downloads}>
                    <img src={bank} alt ='downloads' />
                    <a href='/Downloads'>Downloads</a>

                </div>

            </div>

            <div className={styles.quicklinks_row2}>
                <div className={styles.career}>
                    <img src={Career} alt='Career' />
                    <a href='/Careers'>Careers</a>
                </div>
                <div className={styles.faqs}>
                    <img src={faqs} alt='Faqs' />
                    <a href='/FAQS'>FAQs</a>

                </div>
                <div className={styles.downloads}>
                    <a href='/Downloads'>Downloads</a>

                </div>

            </div>
      </div>*/}

        </div>

        <div className={styles.finances}>
          <div>
            <h4>Are you struggling with financial constraints?</h4>
            <article>
              Worry no more. We've got you covered. With our financial products and services, you'll always be okay
            </article>
          </div>
          <div >
            <img src={financegif} className={styles.financialgif} alt = 'finance gif' />
          </div>
        </div>

        {/*<div>
          <Sidebar/>
      </div>*/}
      <div className={styles.linksq}>
        <div  className={styles.aleft}><GiBranchArrow/></div> 
        
        <div className={styles.aright}><GiBranchArrow/></div>
      </div>

        <div className={styles.featured}>
          <h4>Featured Products</h4>
        </div>

        <div className={styles.cards}>
        <Card isHoverable variant="bordered" className={styles.card1}>
          <Card.Body>
            <img src={bloom35} alt='test' />
            <h5>BOSA Loan Products</h5>
            <a href='/bosa'><button>Learn More</button></a>
          </Card.Body>
        </Card>

        <Card isHoverable variant="bordered">
          <Card.Body>
            <img src={lagom5} alt="placeholder" />
            <h5>Business loans</h5>
            <a href='/business-loans'><button>Learn more</button></a>
          </Card.Body>
        </Card>


        <Card isHoverable variant="bordered">
          <Card.Body>
            <img src={lagomfi} alt="placeholder" />
            <h5>School fees loans</h5>
            <a href='/'><button>Learn more</button></a>
          </Card.Body>
        </Card>

        <Card isHoverable variant="bordered">
          <Card.Body>
            <img src={dude} alt="placeholder" />
            <h5>Wezesha loans</h5>
            <a href='/'><button>Learn more</button></a>
          </Card.Body>
        </Card>
        </div>
    </div>
  )
}

export default Home