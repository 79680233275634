import React, {useEffect} from 'react';
import { trackPageView } from "../utils/GoogleAnalytics";
import { Card } from "@nextui-org/react";
import style from './Aboutus.module.css';
import equality from '../images/equality.png';
import integrity from '../images/integrity.png';
import confidentiality from '../images/confidentiality.png';
import accountability from '../images/accountability.png';

function Aboutus() {
  useEffect(() => {
    trackPageView();
  }, []);
  return (
    <div className={style.abouteverything}>
        <h4 className={style.aboutus}>About us</h4>
        <div className={style.introarticle}>
            <article>
              VITACO SACCO is a member only Co-operative Society that is run and managed by the staff of Vitafoam Products Limited together with its affiliated companies. Members are recruited from within the staff employed by Vitafoam and any other companies that are linked it.
              The sacco is under the Umbrella of the Co-operative ministry and is regulated accordingly by the Ministry. 
            </article>
        </div>
        <div className={style.mvv}>
            <div className={style.mission}>
              <h4>Mission statement</h4>

              <article>To be the leading SACCO providing quality and dynamic services to our members.</article>
            </div>

            <div className={style.vision}>
              <h4>Vision</h4>

              <article>To afford an opportunity for members to mobilize savings, provide affordable credit facilities for promotion of the social economic welfare of members through prudent management and diversification of products, while recognizing the interests of stakeholders.</article>

            </div>
                        
        </div>
            <h4>Our core values</h4>
            <div className={style.cards}>
              <Card variant='shadow' className={style.eqphoto} >
                
                <Card.Body>
                  <img src={equality} alt='Equality'/>
                  <h5>Equality</h5>
                  <Card.Divider></Card.Divider>
                  <Card.Footer>
                    <p>All SACCO members are given equal opportunities to promote themselves through savings and deposits.
</p>
                  </Card.Footer>
                </Card.Body>

              </Card>

              <Card variant='shadow' className={style.intphoto}>
                
                <Card.Body>
                  <img src={integrity} alt='Integrity avatar' />
                  <h5>Integrity</h5>
                  <Card.Divider></Card.Divider>
                  <Card.Footer>
                    <p>Staff and management possess and adhere to high moral principles, ethics and professional standards. 
</p>
                  </Card.Footer>
                </Card.Body>

              </Card>

              <Card variant='shadow' className={style.accphoto}>
                
                <Card.Body>
                  <img src={accountability} alt='Accountability avatar'/>
                  <h5>Accountability</h5>
                  <Card.Divider></Card.Divider>
                  <Card.Footer>
                    <p>The SACCO strives to carry out all its dealings in an accountable manner.
</p>
                  </Card.Footer>
                </Card.Body>

              </Card>



              <Card variant='shadow'>
                
                <Card.Body>
                  <img src={confidentiality} alt='Confidentiality avatar'/>
                  <h5>Confidentiality</h5>
                  <Card.Divider></Card.Divider>
                  <Card.Footer>
                    <p>All stakeholders are advised to observe utmost confidentiality regarding society matters.
</p>
                  </Card.Footer>
                </Card.Body>

              </Card>
            </div>
        <div>

        </div>

        <div className={style.management}>
          <h4>The management committee</h4>
        </div>
    </div>
    
    
  )
}

export default Aboutus