import React, { useState, useEffect } from 'react';
import { trackPageView } from '../utils/GoogleAnalytics';
import { AiFillCaretLeft, AiFillCaretRight, AiOutlineCloseCircle } from "react-icons/ai";
import { Helmet } from 'react-helmet';
import image1 from '../images/DSCN5510.JPG';
import image2 from '../images/DSCN5511.JPG';
import image3 from '../images/DSCN5513.JPG';
import image4 from '../images/DSCN5514.JPG';
import image5 from '../images/DSCN5515.JPG';
import image6 from '../images/DSCN5516.JPG';
import './Gallery.css'

const ImageGrid = () => {
  const [selectedImgIndex, setSelectedImgIndex] = useState(null);

  const images = [
    { url: image1, id: 1 },
    { url: image2, id: 2 },
    { url: image5, id: 3 },
    { url: image4, id: 4 },
    { url: image3, id: 5 },
    { url: image6, id: 6 },
  ];

  useEffect(() => {
    trackPageView();
  }, []);

  const handleClose = () => {
    setSelectedImgIndex(null);
  }

  const handleNext = () => {
    setSelectedImgIndex((prevIndex) => {
      if (prevIndex === images.length - 1) {
        return 0;
      } else {
        return prevIndex + 1;
      }
    });
  }

  const handlePrevious = () => {
    setSelectedImgIndex((prevIndex) => {
      if (prevIndex === 0) {
        return images.length - 1;
      } else {
        return prevIndex - 1;
      }
    });
  }

  return (
    <>
    <Helmet>
      <title>Vitaco | Gallery</title>
      <meta name='description' content="Explore our gallery of images showcasing the work of our microfinance institution, dedicated to empowering entrepreneurs and communities through financial inclusion. See the impact of our programs and the lives we've touched through our efforts to provide access to credit, savings, and training. Join us in our mission to create a world where everyone has the opportunity to thrive."/>
    </Helmet>
    <div className="image-grid">
      {images.map((image, index) => (
        <div key={image.id} className="image-item">
          <img
            src={image.url}
            alt="random"
            onClick={() => setSelectedImgIndex(index)}
          />
        </div>
      ))}
      {selectedImgIndex !== null && (
        <div className="preview-wrapper">
          <div className="preview-background" onClick={handleClose}></div>
          <div className="preview-content">
            <img
              src={images[selectedImgIndex].url}
              alt="selected"
              className="preview-image"
            />
            <div className="close-button" onClick={handleClose}><AiOutlineCloseCircle/></div>
            <div className="prev-button" onClick={handlePrevious}><AiFillCaretLeft/></div>
            <div className="next-button" onClick={handleNext}><AiFillCaretRight/></div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default ImageGrid;


