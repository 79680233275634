import React from "react";
import { motion } from "framer-motion";
import styles from "./LatestNews.module.css";

const LatestNews = () => {
  return (
    <div className={styles.latest_news_container}>
      <div className={styles.latest_news_text}>
        <span>Latest News</span>
        <motion.div
          className={styles.latest_news_content}
          animate={{
            x: ["100vw", "-80vw"],
          }}
          transition={{
            duration: 16,
            ease: 'linear',
            repeat: Infinity,
          }}
        >
          We have new products and services!!!
        </motion.div>
      </div>
    </div>
  );
};

export default LatestNews;

