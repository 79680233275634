import React from 'react'
import styles from './WelfareServices.module.css'

function WelfareServices() {
  return (
    <div>
        <article className={styles.intro}>We look at our members beyond savings and borrowing, we try to ensure that our members are comfortable, healthy and have peace of mind. We are all not able to predict what may happen in the next minute or second. In this regard, Vitaco has put measures in place to support members in times of need.</article>
        <hr></hr>
        <div className={styles.risk}>

            <h4>Risk Fund</h4>
            <ol>
                <li>A compulsory insurance scheme for all members.</li>
                <li>Monthly contribution is Kshs.400. </li>
                <li>The funds are used to offset outstanding loans of the deceased member.</li>
                <li>pay last respect benefit</li>
            </ol>
        </div>

        <div className={styles.burial}>
            <h4>Burial Benevolent Fund</h4>
            <article>It is a mandatory fund used to accord members and their immediate families a decent send off.</article>

            <ul>
                <li>Monthly contribution is Kshs.150</li>
            </ul>
            <article>Document required when lauching  a claim:</article>
            <ol>
                <li>Certified copy of death certificate/burial permit</li>
                <li>Birth notification form/birth certificate (for claims on own child)</li>
                <li>Radio announcement or newspaper caption or letter from assistant chief/head of institution</li>
                <li>Any other supporting documentation.</li>
            </ol>
        </div>
    </div>
  )
}

export default WelfareServices