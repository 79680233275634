import React from 'react';
import './CookiePolicy.css'

const CookiePolicy = () => {
    return (
        <div > 
            <div className='policy'>
                <h3>Cookie Policy</h3>
                <article>Our website uses cookies to improve your browsing experience and to provide you with relevant content and advertisements. This policy explains what cookies are, how they are used on our website, and how you can manage your cookie preferences.</article>

                <h3>What are Cookies?</h3>
                <article>Cookies are small text files that are stored on your device when you visit a website. Cookies are used to remember your preferences and to track your behavior on the website.</article>
                
                <h3>How we use Cookies on our website</h3>
                <article>Our website uses cookies for a number of purposes, including:</article>
                <article>
                <ul>
                    <li>To remember your preferences and settings: Cookies can be used to store your language preference, time zone, and other settings so that you don’t have to enter them every time you visit our website.</li>

                    <li>To track your behavior on the website: Cookies can be used to track your interactions with our website, such as which pages you visit, how often you visit, and how long you spend on the website.</li>

                    <li>To personalize content and advertisements: Cookies can be used to provide you with content and advertisements that are relevant to your interests.</li>

                    <li>To improve the website’s performance: Cookies can be used to collect information about how our website is used, such as which pages are visited the most, which browsers are used, and which devices are used.</li>
                </ul>
                </article>

                <h3>Managing your Cookie Preferences</h3>
                <article>You have the right to manage your cookie preferences. Most web browsers allow you to block or delete cookies, or to receive notifications when new cookies are set. To manage your cookie preferences, please refer to your browser’s help documentation.</article>
                <h3>Changes to this Cookie Policy</h3>
                <article>We may update this Cookie Policy from time to time to reflect changes to our use of cookies or to comply with changes to privacy laws. Please check back regularly for updates</article>
                <span>Effective Date: 6th February, 2023</span>

            </div>
        </div>
    )
}

export default CookiePolicy