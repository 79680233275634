import React, {useEffect} from 'react';
import { trackPageView } from '../utils/GoogleAnalytics';
import styles from './Products.module.css';
import { Helmet } from 'react-helmet';

function Products() {
  const fosa = {
    color: 'black',
  }

  useEffect(() => {
    trackPageView();
  }, []);
  return (
    <div >
      <Helmet>
        <title>Vitaco | Products and Services</title>
        <meta name="description" content="This is a description of my appDiscover our range of financial products and services designed to help entrepreneurs and small businesses grow. From microloans to financial education, we provide the support you need to achieve your goals. Learn more about our offerings and how we can help you succeed." />
      </Helmet>
        <div className={styles.products}>
        <article>We offer the following products and services. Click on one to get more information</article>
        <ul>
            <li> <a href='/bosa-loans'>BOSA loan products</a></li>
            <li style={fosa}>FOSA loan products (Coming Soon)</li>
            <li> <a href='/welfare-services'>Welfare services</a></li>
            <li> <a href='/senior-saver-loans'>Senior saver loans</a></li>
            <li> <a href='/business-loans'>Business Loans</a></li>
        </ul>
        </div>

        <div className={styles.loan}>
          <h4>Requirements during Loan application</h4>
          <ol>
            <li> A copy of National ID Card / Passport</li>
            <li> An ORIGINAL PAYSLIP not more than TWO months old.</li>
            <li> A fully filled OFFICIAL loan application form.</li>
            <li> The loan must be fully guaranteed.</li>
          </ol>

          <h4>Other Conditions applicable</h4>
          <h5>Prorata</h5>

          <article>This is a percentage of savings pegged on the amount of loan a member borrows. Prorata encourages a savings culture. Guess what?……………………………………..your next loan will be bigger than this current one because, prorata will enable you increase your savings and you will also enjoy a very good dividend percentage at the close of the financial year.</article>
        </div>
    </div>
  )
}

export default Products