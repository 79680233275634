import React from 'react';
import { Link } from 'react-router-dom';
//import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'
import { MdLocalPostOffice } from 'react-icons/md'

const Footer = () => {
    const year = new Date().getFullYear();

    return (
    <footer className={styles.foot}>
      <div className={styles.footertop}>
        <div className={styles.socials}>
          <h5>Follow us</h5>
          <span><FaFacebook /></span>
          <span><FaTwitter/></span>
          <span><FaInstagram/></span>

        </div>
        <div className={styles.contactfooter}>
          <h5>Contact details</h5>
          <p>Vitafoam Products Limited Offices</p>
          <p>ICD Road, off Masai Road</p>
          <p>P.O. Box 18094-00500</p>
          <p>NAIROBI</p>
        </div>
        <div className={styles.credits}>
          <h5>Quick links</h5>
          <a href="/products-services" title="Products">Products</a>
          <a href="/gallery" title="Gallery">Gallery</a>
          <a href="/contact-us" title="Contact us">Contact us</a>

        </div>
        <div className={styles.Newsletter}>
          <h5>Subscribe to our newsletter <span><MdLocalPostOffice/></span></h5>

          <input type='email' placeholder='E-mail' className={styles['input-email']}></input>
        
        </div>
      </div>
      <p>&copy; <span>{year}</span> All Rights Reserved.</p>
      <hr />
      <div className={styles.policies}>
      <span><Link to='/privacy-policy'>Privacy Policy</Link></span>
      <span><Link to='/cookie-policy'>Cookie Policy</Link></span>
      <span><Link to='/terms-of-service'>Terms of Service</Link></span>
      
      </div>
    </footer>
    )
}

export default Footer;