import React from 'react'
import { useEffect } from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import { NextUIProvider } from '@nextui-org/react';
import CTA from './components/CTA'
import Home from './pages/Home';
import Navigationbar from './components/Navbar';
import Footer from './components/Footer';
import CookiePolicy from './components/CookiePolicy';
import ContactUs from './pages/Contact-us';
import TermsOfService from './components/TermsOfService';
import Aboutus from './pages/Aboutus';
import Products from './pages/Products-services';
import BOSA from './pages/BOSA';
import BusinessLoans from './pages/BusinessLoans';
import WelfareServices from './pages/WelfareServices';
import SeniorSaver from './pages/SeniorSaver';
import MyGallery from './pages/Gallery';
import { trackPageView } from './utils/GoogleAnalytics'

function App() {
  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <NextUIProvider>
    <Router>
      <CTA/>
      {/*<div className='logodiv'>
        <img src={vitaco} alt='logo' className='logo'/>
      </div>*/} 
      <Navigationbar/>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route path='/cookie-policy' element={<CookiePolicy/>} />
        <Route path='/terms-of-service' element={<TermsOfService/>} />
        <Route path='/about-us' element={<Aboutus/>} />
        <Route path='/contact-us' element={<ContactUs/>} />
        <Route path='/bosa' element={<BOSA/>} />
        <Route path='/products-services' element={<Products/>} />
        <Route path='/bosa-loans' element={<BOSA/>} />
        <Route path='/business-loans' element={<BusinessLoans/>} />
        <Route path='/welfare-services' element={<WelfareServices/>} />
        <Route path='/senior-saver-loans' element={<SeniorSaver/>} />
        <Route path='/gallery' element={<MyGallery/>} />
      </Routes>
    <Footer/>
    </Router>
    </NextUIProvider>
  );
}

export default App;
