import React, {useEffect} from 'react'
import { trackPageView } from '../utils/GoogleAnalytics';
import styles from './BOSA.module.css'

function BOSA() {
  useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div className={styles.bosa}>
        <article>Back Office Services Activities (BOSA) is a department in Vitacco that offers various loan products against member's deposits</article>
        
        <div>
          <h4>Wezesha Loan</h4>
          <article>Unlike ALL other products, WEZESHA (to enable) is the ONLY product that a member does not have to wait for six (6) months before applying for a loan. Vitaco Sacco enables YOU (new member) to access funds within your ability immediately your first  savings deduction goes through. ONLY 1/4 of the amount applied will be retained in the new members account as savings. The loan must be fully guaranteed.</article>
          <h5><strong>Terms and Conditions of Wezesha Loan.</strong></h5>
          <ul>
            <li>This product is for new members only.</li>
            <li>Wezesha loan runs for a maximum of 72 months.</li>
            <li>2% wezesha processing fee shall be charged on the amount to be advanced.</li>
            <li>Maximum loan amount to be determined by the member's ability subject to the loan being fully guaranteed.</li>
            <li>Shares boosted shall earn dividends on pro-rata basis.</li>
          </ul>
        </div>

        <hr></hr>

        <div>
          <h4>Emergency Loan</h4>
          <article>
            <ul>
              <li>Granted for unforeseen circumstances.</li>
              <li>1% p.m interest rate on reducing balance.</li>
              <li>Maximum limit is a member's deposit x5 (subject to 1/3 salary rule)</li>
              <li>Repayable in 24 months</li>
              <li>Only one emergency loan can be granted at a time.</li>
            </ul>
          </article>
        </div>

        <hr></hr>

        <div>
          <h4>School Fees Loans</h4>
          <article>
            <ul>
              <li>Granted for education purposes only.</li>
              <li>1% p.m interest rate on reducing balance.</li>
              <li>Maximum limit is a member's deposit x5 (subject to 1/3 salary rule)</li>
            </ul>
          </article>
        </div>

        <hr></hr>

        <div>
          <h4>Ufanisi Loans</h4>
          <article>
          This is long-term loan product aimed at enabling you our member with salary ability but have low shares to access more credit by boosting your shares. A portion of the loan applied is retained as deposits enhancing your balance multiplier. 
          </article>
          <p><strong>The product features and requirements are:</strong></p>
          <article>
            <ul>
              <li>Loan must be fully guaranteed.</li>
              <li>Deposit multiplier is 5 times the qualifying amount.</li>
              <li>10 % share boosting fee applicable</li>
              <li>Only one Ufanisi loan can be granted at a time.</li>
            </ul>
          </article>
        </div>

        <hr></hr>

        <div>
          <h4>Normal loan</h4>
          <article>
            <ul>
              <li>1% p.m on reducing balance.</li>
              <li>One normal loan to be granted at a time.</li>
              <li>Repayable in 48 months</li>
              <li>Granted as a short term loan aimed at helping members meet their immediate financial needs.</li>
            </ul>
          </article>
        </div>

        <hr></hr>

        <div>
          <h4>Development loan</h4>
          <article>
            <ul>
              <li>Granted as medium-term loan for investment purposes.</li>
              <li>One development loan to be granted at a time.</li>
              <li>Applicable interest rate is 1.125% p.m on reducing balance.</li>
              <li>Maximum limit is a member's deposit x 4 (subject to 1/3 salary rule).</li>
              <li>Repayable in 60 months.</li>
            </ul>
          </article>
        </div>
    </div>
  )
}

export default BOSA
